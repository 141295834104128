const me81 = [
    "%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&%%(*,,...,,,.,(%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%%%%%%&%... .   .....,...,,,,%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%%%%%.. .   ......,. .,.,,.,,,,,,(%&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%%%.      . .   ......,,,..,,,,,..,(&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%%.. .         .....*,***,,,*,.,,*,(&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%...          ..,,,****/////////,,,,(&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%%%...     . .....,*////(((((#####(*,.,&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%%%%%%###.   .,,,*,..***/(//(((#########/**,&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%%%%%######(((..,*/.,...***//((((//,,,*,*//##///%&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%########(((((  ,,,,******* ,..*,*(////((#(/(./,%&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%############(((((/.***. (.).,.* *(#.((*,(.),*/#%(*#(&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%################(((,*////*//*///,*/(##(#((((//((#*%(%(&&&%&&&&&&&&&&&&&&&&&",
    "%%%%##################((**///......,,*//(##%%(/////(#%%%#(#%&&&&&&&&&&&&&&&&&&&&",
    "%%%%##################(((/////////****//(#%%##((/((#%%%%##%%%&&&&&&&&&&&&&&&&&&&",
    "%%%%%##################((///*//,*,**,,,*(#(/((#((/**#%%%/%&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%##################(((*,*,********/**((((##(*/(((#((%%&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%##################((..,**//, *//(#%%%##*(%#(/(**,%%&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%##################(#,.,,////*//((((##%%%###//**&%&%&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%################(((/*..,*/*******((#%%%#(#(,(%%&%&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%###############(((((...,*//,***//(####**,*%%%&&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%##############(((((, **.,,*////*(((///,##%.,&%&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%############((((..,,,**,. .,,..,**,/(#%%%,(/%&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%%%%##########(,...,..,* .*///***/((########//*(*&&&&&&&&&&&&&&&&&&&&&&&&",
    "%%%%%%%%#######/,.,,.......*.....#*/////(((((###(/,*(#/***,,&&&&&&&&&&&&&&&&&&&&",
    "%%%%%####**,/....,.....*.*.../.,  ./(((((((((((/*,//%/**/,*,,***&&&&&&&&&&&&&&&&",
    "%%/,*.,,...,,,,,......,,......      .%#(((((((/*,/// .(*,,*/**,,,***#&&&&&&&&&&&",
    "*,,.,,,.,.,#*...,(...*#... ..        **,(/((/,,.**  .  #*,,,,,(,,**,*,**#&&&&&&&",
    ",,,,,,,,.,.....,....,.....,*....#....,/*.*,*/.....,.,.,..,,/(,,,**,*,(#****/*&&&",
    ",,..,.,./,,,..,,..%....,,..%,.........(***,,,,.,,,,,,,*,,,,,,,,,*,,***&*****%**/",
    ",.,,,.,.,%,,,.,..,&...,%..,.*,...(... ./.,.,.,,**,,,,,,*#,,,/,,*,*,,*******%****",
    "*,/(***,*,***//*/,***%%%%%,,,***,**,%&%&%%%&%%**, ..###,.,**,,,**,.,,#%%&,,,.,,,",
    "-=> Generated from Photo <=-",
]

const me41 = [
    "/((#(&&(/,(((*/.&&*(.,/((*,(, (((*//*/*/",
    "&##/*#/*(#&@#,((/*/#/#%#*%*.*/(/**/**/,/",
    "(##&&(***/*#/ *.,* * .  ,/***/*/,*/*//,/",
    "(@#(#(/#%%/*,,**. .   .* . ,///(/,/*/*,(",
    "%*/#/((,*&//./,    ,          ,***/*****",
    "/(*,&%#(##,.,(######((###(*  .,#,,/**/,*",
    "(((#((#((#/./&&&&&&%&%%&&%%.(/(,,,/*/*/*",
    "&(#((*/*(%###&(.()/#%%(().(#(,*(/,/*,*/*",
    "*#(((//(/##&,&&&&%%%&%%%%&%#%#,,/,**/*/*",
    "%(*((((,(/(/#&%#,##%%%(#(/##,,*,,,*,**/*",
    "&%%%&%&&%&(%&,*#%(((/#/.##*%(/#,.,/,*,/*",
    "((&(/(//&/*( &(,(%%#####( .,,,#,.,/***/*",
    "/((&/*/(%%@*%%%#*.####/, .,., (*,,,,***,",
    "#//%/#@*..%#&(%##(/*//(/ .,.. ,*.***,,,,",
    "#%&&&&#(%,#%(&*/#%####((/,. #,.,.(,*,*,,",
    "*/(#,,*%#*%%&.*//*%%%%%#, #%.#,*%,,%*,,,",
    "-=> Generated from Photo <=-",
]

const floppy19 = [
    ",\'\";-------------------;\"`.",
    ";[]; ................. ;[];",
    ";  ; ................. ;  ;",
    ";  ; ................. ;  ;",
    ";  ; ................. ;  ;",
    ";  ; ................. ;  ;",
    ";  ; ................. ;  ;",
    ";  ; ................. ;  ;",
    ";  `.                 ,'  ;",
    ";    \"\"\"\"\"\"\"\"\"\"\"\"\"\"\"\"\"    ;",
    ";    ,-------------.---.  ;",
    ";    ;  ;\"\";       ;   ;  ;",
    ";    ;  ;  ;       ;   ;  ;",
    ";    ;  ;  ;       ;   ;  ;",
    ";//||;  ;  ;       ;   ;||;",
    ";\\\\||;  ;__;       ;   ;\\/;",
    " `. _;          _  ;  _;  ;",
    "   \" \"\"\"\"\"\"\"\"\"\"\" \"\"\"\"\" \"\"\"",
    "-=> Art by Philip Kaulfuss <=-",
]

const bash42 = [
    "////////////////////////////////////////",
    "////////////////@@/////@@///////////////",
    "////////////@%/////////////@@///////////",
    "///////(@(/////////////////////%@///////",
    "////(&/////////////////////////////@////",
    "////@///////////////////////////@@@@@///",
    "////@///////////////////////@@@@@@@@@///",
    "////@///////////////////@@@@@@@@@@@@@///",
    "////@///////////////#@@@@@@@@@@@@@@@@///",
    "////@///////////////@@@@(@&@@@@@@@@@@///",
    "////@///////////////@@@/@@@@@@@@#/@@@///",
    "////@///////////////@@@@#//@@@#/@@@@@///",
    "/////@#/////////////@@@(//@@#/@@@@@@////",
    "/////////@&/////////@@@@@@@@@@@@////////",
    "/////////////@@/////@@@@@@@&////////////",
    "/////////////////&@(/@@#////////////////",
    "////////////////////////////////////////",
    "-=> Generated from Photo <=-",
]

const butterfly19 = [
    "                `         '",
    ";,,,             `       '             ,,,;",
    "`YES8888bo.       :     :       .od8888YES'",
    "  888IO8DO88b.     :   :     .d8888I8DO88",
    "  8LOVEY'  `Y8b.   `   '   .d8Y'  `YLOVE8",
    " jTHEE!  .db.  Yb. '   ' .dY  .db.  8THEE!",
    "   `888  Y88Y    `b ( ) d'    Y88Y  888'",
    "    8MYb  \'\"        ,',        \"\'  dMY8",
    "   j8prECIOUSgf\"\'   \':\'   `\"?g8prECIOUSk",
    "     'Y'   .8'     d' 'b     '8.   'Y'",
    "      !   .8' db  d'; ;`b  db '8.   !",
    "         d88  `'  8 ; ; 8  `'  88b",
    "        d88Ib   .g8 ',' 8g.   dI88b",
    "       :888LOVE88Y'     'Y88LOVE888:",
    "       '! THEE888'       `888THEE !'",
    "          '8Y  `Y         Y'  Y8'",
    "           Y                   Y",
    "           !                   !",
    "-=> Art by Anonymous <=-",
]

const raspberrypi11 = [
    "    .~~.   .~~.",
    "    '. \ ' ' / .'",
    "     .~ .~~~..~.",
    "    : .~.'~'.~. :",
    "   ~ (   ) (   ) ~",
    "  ( : '~'.~.'~' : )",
    "   ~ .~ (   ) ~. ~",
    "    (  : '~' :  )",
    "     '~ .~~~. ~'",
    "         '~'",
    "-=> Raspberry Pi <=-",
]

const omega7 = [
    "  .d88888888b.",
    " d88P\"    \"Y88b",
    " 888        888",
    " Y88b      d88P",
    "  \"88bo  od88\"",
    " d88888  88888b",
    " -=> Art by jshepher@laurel.ocs.mq.edu.au <=-"
]

const xinu8 = [
    "------------------------------------------",
    "   __    __   _____    _   _    _    _    ",
    "   \\ \\  / /  |__ __|  | \\ | |  | |  | |   ",
    "    \\ \\/ /     | |    |  \\| |  | |  | |   ",
    "    / /\\ \\    _| |_   | \\   |  | |  | |   ",
    "   / /  \\ \\  |     |  | | \\ |  \\  --  /   ",
    "   --    --   -----    -   -     ----     ",
    "------------------------------------------",
]

const chessboard = [
    "  +------------------------+",
	"8 | r  n  b  q  k  b  n  r |",
	"7 | p  p  p  p  p  p  p  p |",
	"6 |:::   :::   :::   :::   |",
	"5 |   :::   :::   :::   :::|",
	"4 |:::   :::   :::   :::   |",
	"3 |   :::   :::   :::   :::|",
	"2 | P  P  P  P  P  P  P  P |",
	"1 | R  N  B  Q  K  B  N  R |",
	">>+------------------------+",
	"    a  b  c  d  e  f  g  h  ",
]

const resume = [
    "|-------------------------------------------------------------------------------------------|",
    "|                     __                                          LinkedIn /in/voigniersmith|",
    "| /\\  _  _| _ _      (_  _ .|_|_                                       Github /voigniersmith|",
    "|/--\\| )(_|| (- \\)/  __)|||||_| )                                Portfolio voigniersmith.com|",
    "|voigniersmith@gmail.com • (812) 406-9444                                                   |",
    "|-------------------------------------------------------------------------------------------|",
    "|EDUCATION                                                                                  |",
    "|Purdue University - West Lafayette, Indiana                                                |",
    "|Pursuing a Master of Science in Computer Science                   May 2023 | GPA 3.6 / 4.0|",
    "|Bachelor of Science in Computer Science                            May 2022 | GPA 3.6 / 4.0|",
    "|    Focus in Software Engineering & Mathematics Minor, Dean's List: '20, '21, '22          |",
    "|-------------------------------------------------------------------------------------------|",
    "|EXPERIENCE                                        |PROJECTS                                |",
    "|Amazon AWS                                        |Parallel-Search Chess Engine | Github   |",
    "|EC2 VPC Software Engineer Intern | Summer 2022    | - Built a chess engine from scratch and|",
    "| - Built an API Gateway REST service connected    |   a chess move prediction bot using    |",
    "|   to a Fargate container instance for querying   |   Minimax search                       |",
    "|   our team's internal services                   | - Parallelized the chess bot by        |",
    "| - Configured Route53 DNS web service &           |   processing depth-1 trees on multiple |",
    "|   PrivateLinkInterface Endpoint for external     |   processors                           |",
    "|   customer requests                              | - Implemented alpha-beta pruning to    |",
    "| - Automatically generated Lambda Functions for   |   reduce the number of trees requiring |",
    "|   integration testing                            |   exploration                          |",
    "|IBM                                               |Automated Bug Detection Tool | Github   |",
    "|Software Developer Intern | Summer 2021           | - Calculated likely-invariants from    |",
    "| - Created a custom grafana plugin using React    |   call graphs to automatically detect  |",
    "|   SASS Carbon Design System                      |   software bugs                        |",
    "| - Developed a RESTful microservice for           | - Generated call graphs using the Clang|",
    "|   aggregating data on a Fastify Framework to     |   compiler to compute probable bugs    |",
    "|   achieve significant performance gains          |   within Java                          |",
    "| - Learned IBM Cloud Microservices including      | - Reduced false positives by a factor  |",
    "|   Containers, Kubernetes, and Docker             |   of 25% by excluding commonly paired  |",
    "|Purdue University                                 |   functions                            |",
    "|Graduate Teaching Assistant | Aug 2021 - May 2023 |Moodify | Web Application - Github      |",
    "| - Assisted in daily course operations and        | - Developed a full MERN stack          |",
    "|   provided instructional aide to course students |   application                          |",
    "| - Graded and troubleshooted course content while | - Utilized the Spotify WebSDK & API    |",
    "|   mentoring students                             |   for an improved user experience      |",
    "| - Conducted laboratory sessions and performed    | - Real-time music suggestion based on  |",
    "|   administrative tasks                           |   community-recommended emotional      |",
    "|Research Assistant | Nov 2020 - May 2021          |   descriptions                         |",
    "| - Developed a mixed reality application for the  |----------------------------------------|",
    "|   Microsoft Hololens 2                           |SKILLS | Programming                    |",
    "| - Manually configured a CI/CD pipeline to        |Over 10,000 Lines: C/C++, Java,         |",
    "|   generate an AR-ready framework                 |   JavaScript, Bash                     |",
    "| - Completed support documentation and remote     |Over 1,000 Lines: React, Unity/C#,      |",
    "|   setup for future continuous development        |Familiar: Kubernetes, Docker, YAML, ARM |",
    "|-------------------------------------------------------------------------------------------|",
    "|COURSEWORK                                                                                 |",
    "|Data Structures and Algorithms, Operating Systems (Graduate Teaching Assistant),           |",
    "|Systems Programming (Undergrad Teaching Assistant), Software Engineering,                  |",
    "|Computer Architecture, Analysis of Algorithms, Networking, Parallel Computing,             |",
    "|Natural Language Processing, Computer Graphics, Cryptography                               |",
    "|-------------------------------------------------------------------------------------------|",
]

export { me81, me41, floppy19, bash42, butterfly19, raspberrypi11, omega7, xinu8, chessboard, resume };